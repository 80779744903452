import React from "react"

import "./impressum.styl"

export default () => (
  <div id="impressum" className="main-page">
    <h1 className="page-title">
      Impressum
      <div className="uk-grid">
        <div className="uk-width-2-2">
          <h3>
            LEBENSKREIS APOTHEKE KG
            <br />
            <p>
              Tischlerstra&szlig;e 15
              <br />
              8740 Zeltweg, &Ouml;sterreich
              <br />
              <br />
              Tel.: +43 3577/242 55
              <br />
              Fax.: 43 3577/242 55-4
              <br />
              info@lebenskreis-apotheke.at <br />
            </p>
          </h3>
          <h3>
            Informationen lt. ECG
            <p>
              UID Nummer: ATU 49397905 <br />
              Firmenbuch Nummer: FN 142518 k <br />
              Firmenbuchgericht: Leoben <br />
              Zuständige Aufsichtsbehörde: <br />
              BM für soziale Sicherheit und Generationen <br />
              "Das Unternehmen ist eine öffentliche Apotheke nach
              österreichischem Apothekengesetz. Sein Leiter ist Mitglied der
              Österreichischen Apothekerkammer. <br />
              Berufsrechtliche Vorschrift: Apothekengesetz". <br />
              Weitere Informationen finden Sie auf www.apotheker.or.at unter der
              Rubrik Apothekerkammer/Themenbereiche/Information der
              Rechtsabteilung/Apothekenrelevante Gesetze und Verordnungen.
            </p>
          </h3>
        </div>
      </div>
    </h1>
  </div>
)
